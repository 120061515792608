;(function ($, window, document) {
    'use strict';
    $(document).ready(function () {

        // Slick -- Carousel Tops Sorties

        $(window).resize(function () {
            setTimeout(function () {
                $('.next, .prev').addClass('active');
            }, 1500);
        });


        // Slick Init
        $('.slick').each(function () {
            var $this = $(this);
            var $slick = $(this);

            $slick.slick({
                infinite: true,
                nextArrow: '<span class="next"><i class="fas fa-angle-right"></i></span>',
                prevArrow: '<span class="prev"><i class="fas fa-angle-left"></i></span>',
                slidesToShow: 6,
                slidesToScroll: 6,
                accessibility: false,
                draggable: false,
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            arrows: false,
                            speed: 500,
                            draggable: true,
                            accessibility: false,
                            touchThreshold: 9,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                            arrows: false,
                            draggable: true,
                            speed: 500,
                            accessibility: false,
                            touchThreshold: 9,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 1000,
                        settings: {
                            slidesToShow: 5,
                            draggable: true,
                            slidesToScroll: 5,
                            arrows: false,
                            speed: 500,
                            accessibility: false,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 1200,
                        settings: {
                            draggable: false,
                            infinite: true,
                            arrows: true,
                            nextArrow: '<span class="next"><i class="fas fa-angle-right"></i></span>',
                            prevArrow: '<span class="prev"><i class="fas fa-angle-left"></i></span>',
                            slidesToShow: 6,
                            slidesToScroll: 6,
                            speed: 500
                        }
                    }
                ]
            });

            setTimeout(function () {
                $slick.find('.next, .prev').addClass('active');
            }, 1500);


            //-------------------------------
            // LazyLoad
            //-------------------------------
            var destroyLoader = function () {
                $this.find('.loading').addClass('fadeout');
                cleanNoSources();
            };

            var cleanNoSources = function () {
                var $nosources = $('.slick').find('.lazy[data-src=""]');
                $nosources.removeAttr('src');
            };

            var cleanNotFound = function () {
                $(this).removeAttr('src')
            };

            $slick.on('beforeChange', function () {
                $this.find('.slick-active + .slick-slide .lazy').lazy({
                    threshold: 200,
                    onError: cleanNotFound()
                });
                cleanNoSources();
            });

            $slick.on('swipe', function () {
                $this.find('.slick-active .lazy').lazy({
                    threshold: 200,
                    onError: cleanNotFound()
                });
                cleanNoSources();
            });

            $this.find('.slick-active .lazy').show().lazy({
                onFinishedAll: destroyLoader(),
                threshold: 200
            });
            //-------------------------------

        });

        $('.tab-filter').each(function () {
            var $this = $(this);
            var $slick = $this.find('.slick');
            $this.on('shown.bs.tab', '[data-toggle="tab"]', function (e) {
                $(window).trigger('scroll');
            });
            var filterSlick = function (filter) {
                $slick.slick('slickUnfilter');
                $slick.slick('slickFilter', filter);
            };



            // Filter slick
            $this.on('click', '[slick-filter]', function () {
                // Filter slick slides based on city ID
                filterSlick($(this).attr('slick-filter'));
                $this.find('.slick-active .lazy').lazy({
                    threshold: 200
                });
                cleanNoSources();
                setTimeout(function () {
                    $this.find('.next, .prev').addClass('active');
                }, 100);
            });

            filterSlick($this.find('li.active [slick-filter]').attr('slick-filter'));

        });


    });
})(jQuery, window, document);



/*------------------------------------*\
 SLICK MAIN CAROUSEL
\*------------------------------------*/

(function ($) {
    'use strict';

    // Slick -- Carousel Main slider
    $('.main-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        autoplay: true,
        autoplaySpeed: 5000,
        asNavFor: '.main-slider-nav',
        accessibility: false,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    fade: false,
                    dots: true,
                    accessibility: false,
                }
            }
        ]
    });

    $('.main-slider-nav').slick({
        vertical: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        focusOnSelect: true,
        asNavFor: '.main-slider',
        accessibility: false,
        nextArrow: '<span class="next"><i class="fa fa-angle-down" aria-hidden="true"></i></span>'
    });

    $('.main-slider-nav').on('mouseenter', function () {
        $('.main-slider').slick('slickPause');
    });

    $('.main-slider-nav').on('mouseleave', function () {
        $('.main-slider').slick('slickPlay');
    });


    $('[data-slick-action="prev"]').css('cursor', 'pointer').on('click', function (e) {
        $($(this).data('target')).slick('slickPrev');
    });
    $('[data-slick-action="next"]').css('cursor', 'pointer').on('click', function (e) {
        $($(this).data('target')).slick('slickNext');
    });

})(jQuery);